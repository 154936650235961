import React, { useEffect, useState } from 'react';
import InputField from '../../../../components/Input';
import { ButtonBackForm, ButtonNextForm } from '../../../home/styledMui';
import { createResponseEvent } from '../../../../utils/interceptor';
import { stepsData } from '../../stepsData';
import { Props } from './models';
import { ButtonWrapper, GridCenter, TittleSection } from '../../styled';

const PaymentDataInformation: React.FC<Props> = ({
  activeStep,
  handleSubmit,
  completedStep,
  resetForm,
  handleBack,
  registerIdLog,
  registerEmailLog
}) => {
  const [disabledButton, setdisabledButton] = useState(true);
  const [paymentDataInformation, setpaymentDataInformation] = useState({
    fiscalAddress: '',
    zipCode: '',
    bankName: '',
    accountNumber: '',
    swiftCode: '',
    bankAddress: '',
  });
  const handleChange = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setpaymentDataInformation({
        ...paymentDataInformation,
        [name]: value
      })
    }
    catch (error) {
      let json: string = paymentDataInformation ? JSON.stringify(paymentDataInformation) : '';

      createResponseEvent({
        commandtrigger: "payment_information_accounting_data",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleChange: ${error} - ${json}` 
      });
    }
  };

  const handleChangeNumber = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setpaymentDataInformation({
        ...paymentDataInformation,
        [name]: value
      })
    }
    catch (error) {
      let json: string = paymentDataInformation ? JSON.stringify(paymentDataInformation) : '';

      createResponseEvent({
        commandtrigger: "payment_information_accounting_data",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleChangeNumber: ${error} - ${json}` 
      });
    }
  };

  const cleanDataForm = () => {
    setpaymentDataInformation({
      fiscalAddress: '',
      zipCode: '',
      bankName: '',
      accountNumber: '',
      swiftCode: '',
      bankAddress: '',
    })
  };

  const handleSubmitForm = (ev: any) => {
    try {
      ev.preventDefault();
      handleSubmit && handleSubmit(paymentDataInformation, "accountingData");
      completedStep && completedStep(true, 'paymentDataInformation');
    }
    catch (error) {
      let json: string = paymentDataInformation ? JSON.stringify(paymentDataInformation) : '';

      createResponseEvent({
        commandtrigger: "payment_information_accounting_data",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleSubmitForm: ${error} - ${json}` 
      });
    }
  };

  useEffect(() => {
    resetForm && cleanDataForm();
  }, [resetForm]);
  return (
    <>
      {stepsData[2].id === activeStep && (
        <form onSubmit={handleSubmitForm}>
          <TittleSection>
            <h2>Payment Information</h2>
            <span>Accounting data</span>
          </TittleSection>
          <GridCenter item xs={12}>
            <InputField
              label="Fiscal Address"
              placeholder="Ex.: Longgang qu nanwan jiedao pingji dadao 1 hao"
              disabled={false}
              name="fiscalAddress"
              value={paymentDataInformation.fiscalAddress}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Zip code"
              placeholder="Ex.: 518000"
              disabled={false}
              name="zipCode"
              value={paymentDataInformation.zipCode || ''}
              onChange={handleChangeNumber}
              type="number"
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Bank name"
              placeholder="Ex.: Bank of China (Hong Kong) Limited"
              disabled={false}
              name="bankName"
              value={paymentDataInformation.bankName}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Account number"
              placeholder="Ex.: 601-92-14471-8 (USD)"
              disabled={false}
              name="accountNumber"
              value={paymentDataInformation.accountNumber}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Swift code"
              placeholder="Ex.: BKCHHKHHXXX"
              disabled={false}
              name="swiftCode"
              value={paymentDataInformation.swiftCode}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Bank Address"
              placeholder="Ex.: Flat /Rm 14 BLK A 7/F Sun Fung Centre 88 "
              disabled={false}
              name="bankAddress"
              value={paymentDataInformation.bankAddress}
              onChange={handleChange}
            />
          </GridCenter>
          <ButtonWrapper>
            <ButtonBackForm fullWidth onClick={() => handleBack()} variant="contained">
              Back
            </ButtonBackForm>
            <ButtonNextForm fullWidth type='submit' variant="contained">
              Continue
            </ButtonNextForm>
          </ButtonWrapper>
        </form>
      )}
    </>
  )
};

export default PaymentDataInformation;