import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import InputField from '../../../../components/Input';
import AutoCompleteField from '../../../../components/Autocomplete';
import { ButtonNextForm, InputAgora } from '../../../home/styledMui';
import { createResponseEvent } from '../../../../utils/interceptor';
import { categoryData } from '../../data';
import { stepsData } from '../../stepsData';
import { ButtonWrapper, GridCenter, TittleSection } from '../../styled';
import { Props } from './models';

const CompanyInformation: React.FC<Props> = ({
  activeStep,
  handleSubmit,
  completedStep,
  resetForm
}) => {
  const [disabledButton, setdisabledButton] = useState(true);
  const [countrySelect, setCountrySelected] = useState(null);
  const [companyInformation, setCompanyInformation] = useState({
    briefCompanyDescription: '',
    companyOperationCountry: '',
    legalEntityName: '',
    productCategory: '',
    shopName: '',
    taxID: '',
  });

  const handleChange = (ev: any) => {
    try {
      const { name, value } = ev.target;

      setCompanyInformation({
        ...companyInformation,
        [name]: value
      });
    } catch (error) {
      let json: string = companyInformation ? JSON.stringify(companyInformation) : '';

      createResponseEvent({
        commandtrigger: "company_information",
        commandtag: "user_event_error_form_international",
        pageError: `handleChange: ${companyInformation?.legalEntityName} - ${error} - ${json}` 
      });
    }
  };

  const handleChangeTaxId = (ev: any) => {
    try {
      const { name, value } = ev.target;

      setCompanyInformation({
        ...companyInformation,
        [name]: value
      });
    } catch (error) {
      let json: string = companyInformation ? JSON.stringify(companyInformation) : '';

      createResponseEvent({
        commandtrigger: "company_information",
        commandtag: "user_event_error_form_international",
        pageError: `handleChangeTaxId: ${companyInformation?.legalEntityName} - ${error} - ${json}` 
      });
    }
  };

  const handleChangeCountry = (ev: any, newValue: any) => {
    try {
      setCompanyInformation({
        ...companyInformation,
        companyOperationCountry: newValue?.name
      });
      setCountrySelected(newValue);
    } catch (error) {
      createResponseEvent({
        commandtrigger: "company_information",
        commandtag: "user_event_error_form_international",
        pageError: `handleChangeCountry: ${companyInformation?.legalEntityName} - ${error} - ${newValue}` 
      });
    }
  };

  const handleSubmitForm = (ev: any) => {
    try {
      ev.preventDefault();
      completedStep && completedStep(true, 'companyInformation');
      handleSubmit && handleSubmit(companyInformation, "companyInformation");
    } catch (error) {
      let json: string = companyInformation ? JSON.stringify(companyInformation) : '';

      createResponseEvent({
        commandtrigger: "company_information",
        commandtag: "user_event_error_form_international",
        pageError: `handleSubmitForm: ${companyInformation?.legalEntityName} - ${error} - ${json}` 
      });
    }
  };

  const cleanDataForm = () => {
    setCompanyInformation({
      briefCompanyDescription: '',
      companyOperationCountry: '',
      legalEntityName: '',
      productCategory: '',
      shopName: '',
      taxID: '',
    });
    setCountrySelected(null)
  };

  useEffect(() => {
    resetForm && cleanDataForm();
  }, [resetForm]);

  return (
    <>
      {stepsData[0].id === activeStep && (
        <form onSubmit={handleSubmitForm}>
          <TittleSection>
            <h2>Company Information</h2>
          </TittleSection>
          <GridCenter item xs={12}>
            <InputField
              label="Legal entity name"
              placeholder="Ex.: Tiendas Peruanas"
              disabled={false}
              name="legalEntityName"
              value={companyInformation.legalEntityName}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Tax ID/Business registration number"
              placeholder="Ex.: 1826484-1"
              disabled={false}
              name="taxID"
              value={companyInformation.taxID}
              onChange={handleChangeTaxId}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Shop name"
              placeholder="Ex.: Plaza Vea"
              disabled={false}
              name="shopName"
              value={companyInformation.shopName}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Brief company description"
              placeholder="Ex.: I sell vegan products"
              disabled={false}
              name="briefCompanyDescription"
              value={companyInformation.briefCompanyDescription}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel shrink id='productCategory'>Product category</InputLabel>
              <Select
                labelId='productCategory'
                id='productCategory'
                value={companyInformation.productCategory}
                name="productCategory"
                onChange={handleChange}
                input={<InputAgora />}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>Choose the option</em>
                </MenuItem>
                {categoryData.map((item: any) => (
                  <MenuItem key={item.id} value={item.label}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridCenter>
          <GridCenter item xs={12}>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <AutoCompleteField
                name="companyOperationCountry"
                value={countrySelect}
                onChange={handleChangeCountry}
                label="Company operation country"
                placeholder="Choose the option"
              />
            </FormControl>
          </GridCenter>
          <ButtonWrapper>
            <ButtonNextForm fullWidth type='submit' variant="contained">
              Continue
            </ButtonNextForm>
          </ButtonWrapper>
        </form>
      )}
    </>
  )
};

export default CompanyInformation;