import React, { useEffect, useState } from 'react';
import InputField from '../../../../components/Input';
import { ButtonBackForm, ButtonNextForm } from '../../../home/styledMui';
import { createResponseEvent } from '../../../../utils/interceptor';
import { stepsData } from '../../stepsData';
import { Props } from './models';
import {
  ButtonWrapper,
  GridCenter,
  TittleSection
} from '../../styled';

const ShippingInformation: React.FC<Props> = ({
  activeStep,
  handleSubmit,
  completeStepsLabel,
  completedStep,
  resetForm,
  handleBack,
  registerIdLog,
  registerEmailLog
}) => {
  const [shippingInformation, setshippingInformation] = useState({
    warehouseAddress: '',
    country: '',
    city: '',
    postalCode: '',
  });

  const handleChange = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setshippingInformation({
        ...shippingInformation,
        [name]: value
      })
    }
    catch (error) {
      let json: string = shippingInformation ? JSON.stringify(shippingInformation) : '';

      createResponseEvent({
        commandtrigger: "shipping_information",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleChange: ${error} - ${json}` 
      });
    }
  };

  const cleanDataForm = () => {
    setshippingInformation({
      warehouseAddress: '',
      country: '',
      city: '',
      postalCode: '',
    });
  };

  const handleChangeNumber = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setshippingInformation({
        ...shippingInformation,
        [name]: value
      })
    }
    catch (error) {
      let json: string = shippingInformation ? JSON.stringify(shippingInformation) : '';

      createResponseEvent({
        commandtrigger: "shipping_information",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleChangeNumber: ${error} - ${json}` 
      });
    }
  };

  const handleSubmitForm = async (ev: any) => {
    try {
      handleSubmit && handleSubmit(shippingInformation, "shippingInformation");
      ev.preventDefault();
    }
    catch (error) {
      let json: string = shippingInformation ? JSON.stringify(shippingInformation) : '';

      createResponseEvent({
        commandtrigger: "shipping_information",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleSubmitForm: ${error} - ${json}` 
      });
    }
  };

  const onBlur = async (ev: any) => {
    createResponseEvent({
      commandtrigger: "shipping_information_field",
      commandtag: "user_event_set_form_international",
      sessionId: registerIdLog,
      emailAddress: registerEmailLog,
      formValues: {...shippingInformation}
    });
  }

  useEffect(() => {
    resetForm && cleanDataForm();
  }, [resetForm]);
  return (
    <>
      <form className={stepsData[4].id === activeStep ? "view" : "notView"} onSubmit={handleSubmitForm}>
        <TittleSection>
          <h2>Shipping Information</h2>
        </TittleSection>
        <GridCenter item xs={12}>
          <InputField
            label="Warehouse address"
            placeholder="Ex.: Flat /Rm 14 BLK A 7/F Sun Fung Centre 88"
            disabled={false}
            name="warehouseAddress"
            value={shippingInformation.warehouseAddress}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </GridCenter>
        <GridCenter item xs={12}>
          <InputField
            label="Country"
            placeholder="Ex.: China"
            disabled={false}
            name="country"
            value={shippingInformation.country}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </GridCenter>
        <GridCenter item xs={12}>
          <InputField
            label="City"
            placeholder="Ex.: Beijing"
            disabled={false}
            name="city"
            value={shippingInformation.city}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </GridCenter>
        <GridCenter item xs={12}>
          <InputField
            label="Postal code"
            placeholder="Ex.: 102600"
            disabled={false}
            name="postalCode"
            value={shippingInformation.postalCode || ''}
            onChange={handleChangeNumber}
            type="number"
            onBlur={onBlur}
          />
        </GridCenter>
        <ButtonWrapper>
          <ButtonBackForm fullWidth onClick={() => handleBack()} variant="contained">
            Back
          </ButtonBackForm>
          <ButtonNextForm fullWidth type='submit' variant="contained">
            Continue
          </ButtonNextForm>
        </ButtonWrapper>
      </form>
    </>
  )
};

export default ShippingInformation;