import { FormControl } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputField from '../../../../components/Input';
import InputCountries from '../../../../components/InputCountries';
import { ButtonBackForm, ButtonNextForm } from '../../../home/styledMui';
import { createResponseEvent } from '../../../../utils/interceptor';
import { stepsData } from '../../stepsData';
import { Props } from './models';
import { ButtonWrapper, GridCenter, LabelInput, TittleSection } from '../../styled';

const ContactInformation: React.FC<Props> = ({
  activeStep,
  handleSubmit,
  completedStep,
  resetForm,
  handleBack,
  registerIdLog,
  registerEmailLog
}) => {
  const [disabledButton, setdisabledButton] = useState(true);
  const [phoneNumberCountry, setphoneNumberCountry] = useState("");
  const [contactInformation, setcontactInformation] = useState({
    representativeName: '',
    emailAddress: '',
    confirmEmail: '',
    phoneNumber: 0,
    countryCodePhoneNumber: '',
  });
  const [errorContactInformation, seterrorContactInformation] = useState({
    representativeName: '',
    emailAddress: '',
    confirmEmail: '',
    phoneNumber: '',
  });

  const handleChange = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setcontactInformation({
        ...contactInformation,
        [name]: value
      });
    } catch (error) {
      let json: string = contactInformation ? JSON.stringify(contactInformation) : '';

      createResponseEvent({
        commandtrigger: "contact_information",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleChange: ${error} - ${json}` 
      });
    }
  };

  const handleChangeEmail = (ev: any) => {
    try {
      const { name, value } = ev.target;
      const message = "The email has to be the same"
      setcontactInformation({
        ...contactInformation,
        [name]: value
      });
    } catch (error) {
      let json: string = contactInformation ? JSON.stringify(contactInformation) : '';

      createResponseEvent({
        commandtrigger: "contact_information",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleChangeEmail: ${error} - ${json}` 
      });
    }
  };
  const handleChangePhoneCountry = (countryCode: any, number: any, value: any) => {
    try {
      const { dialCode } = countryCode;
      const codeCountry = `+${dialCode}`;
      const phoneOnly = number.split(codeCountry);
      setphoneNumberCountry(value);
      const phoneNumbersText = phoneOnly.length === 2 && phoneOnly[1].replace(/\D/g, '');
      setcontactInformation({
        ...contactInformation,
        countryCodePhoneNumber: codeCountry,
        phoneNumber: phoneNumbersText
      });
    } catch (error) {
      let json: string = contactInformation ? JSON.stringify(contactInformation) : '';

      createResponseEvent({
        commandtrigger: "contact_information",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleChangePhoneCountry: ${error} - ${json}` 
      });
    }
  };

  const cleanDataForm = () => {
    setcontactInformation({
      representativeName: '',
      emailAddress: '',
      confirmEmail: '',
      phoneNumber: 0,
      countryCodePhoneNumber: '',
    });
    setphoneNumberCountry('');
  };

  const handleSubmitForm = (ev: any) => {
    try {
      ev.preventDefault();
      handleSubmit && handleSubmit(contactInformation, "contactInformation");
      completedStep && completedStep(true, 'contactInformation');
    } catch (error) {
      let json: string = contactInformation ? JSON.stringify(contactInformation) : '';

      createResponseEvent({
        commandtrigger: "contact_information",
        commandtag: "user_event_error_form_international",
        sessionId: registerIdLog || '',
        emailAddress: registerEmailLog || '',
        pageError: `handleSubmitForm: ${error} - ${json}` 
      });
    }
  };

  useEffect(() => {
    resetForm && cleanDataForm();
  }, [resetForm]);
  return (
    <>
      {stepsData[1].id === activeStep && (
        <form onSubmit={handleSubmitForm}>
          <TittleSection>
            <h2>Contact Information</h2>
          </TittleSection>
          <GridCenter item xs={12}>
            <InputField
              label="Representative legal name"
              placeholder="Ex.: Alessandra Carter"
              disabled={false}
              name="representativeName"
              value={contactInformation.representativeName}
              error={errorContactInformation.representativeName}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Email address"
              placeholder="Ex.: assancar@gmail.com"
              disabled={false}
              name="emailAddress"
              value={contactInformation.emailAddress}
              error={errorContactInformation.emailAddress}
              onChange={handleChange}
              type="email"
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Confirm your email address"
              placeholder="Ex.: assancar@gmail.com"
              disabled={false}
              name="confirmEmail"
              value={contactInformation.confirmEmail}
              error={errorContactInformation.confirmEmail}
              onChange={handleChangeEmail}
              type="email"
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <LabelInput>
                Phone number
              </LabelInput>
              <InputCountries
                onChangeInput={handleChangePhoneCountry}
                value={phoneNumberCountry}
                placeholder="Ex.: +1 392 4908 31"
              />
            </FormControl>
          </GridCenter>
          <ButtonWrapper>
            <ButtonBackForm fullWidth onClick={() => handleBack()} variant="contained">
              Back
            </ButtonBackForm>
            <ButtonNextForm fullWidth type='submit' variant="contained">
              Continue
            </ButtonNextForm>
          </ButtonWrapper>
        </form>
      )}
    </>
  )
};

export default ContactInformation;