import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import Swal from 'sweetalert2';
import { ErrorBoundary } from "react-error-boundary";
import { icons } from '../../assets/icons';
import { Icon } from '../../components/Icon';
import ModalLoader from '../../components/Modal';
import { StepperSection } from '../../components/Steps';
import { COLORS, STATUS } from '../../ui/variable';
import CompanyInformation from './components/CompanyInformation';
import ContactInformation from './components/ContactInformation';
import PaymentContactInformation from './components/PaymentContactInformation';
import PaymentDataInformation from './components/PaymentDataInformation';
import ShippingInformation from './components/ShippingInformation';
import { initialValues, IPreRegister, IStepsInformation } from './model';
import { preregistationService } from './state/service';
import { stepsData } from './stepsData';
import { STEPS, createResponseEvent } from '../../utils/interceptor';
import { BackWrapper, ContentWrapper, Label, RegisterWrapper } from './styled';

let registerIdLog: string = '';
let registerEmailLog = '';

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [stepsInformation, setstepsInformation] = useState<IStepsInformation[]>([...stepsData])
  const [loadSubmit, setloadSubmit] = useState(false);
  const [resetForm, setresetForm] = useState(false);
  const [dataRegister, setdataRegister] = useState<IPreRegister>({
    companyInformation: {
      briefCompanyDescription: '',
      companyOperationCountry: '',
      legalEntityName: '',
      productCategory: '',
      shopName: '',
      taxID: '',
    },
    contactInformation: {
      representativeName: '',
      emailAddress: '',
      confirmEmail: '',
      phoneNumber: 0,
      countryCodePhoneNumber: '',
    },
    paymentInformation: {
      accountingContact: {
        firstName: '',
        lastName: '',
        occupation: '',
        landline: '',
        phoneNumber: 0,
        countryCodePhoneNumber: '',
        emailAddress: '',
        billingAddress: ''
      },
      accountingData: {
        fiscalAddress: '',
        zipCode: 0,
        bankName: '',
        accountNumber: '',
        swiftCode: '',
        bankAddress: '',
      }
    },
    shippingInformation: {
      warehouseAddress: '',
      country: '',
      city: '',
      postalCode: 0,
    },
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const saveLogInEachStep = (data: any, nameStep: string) => {
    registerIdLog = !registerIdLog ? nanoid() : registerIdLog;

    if (!registerEmailLog) {
      registerEmailLog = nameStep === 'contactInformation' ? data?.emailAddress : '';
    }

    if (nameStep !== 'shippingInformation') {
      createResponseEvent({
        commandtrigger: STEPS[nameStep],
        commandtag: "user_event_set_form_international",
        sessionId: registerIdLog,
        emailAddress: registerEmailLog,
        formValues: {...data},
      });
    }
  }

  const sendData = (data: any, nameStep: string) => {
    if (nameStep === "accountingContact" || nameStep === "accountingData") {
      setdataRegister({
        ...dataRegister,
        paymentInformation: {
          ...dataRegister.paymentInformation,
          [nameStep]: data
        }
      });
    } else {
      setdataRegister({
        ...dataRegister,
        [nameStep]: data
      });
    }
    saveLogInEachStep(data, nameStep);
    (nameStep === "shippingInformation") ? completeDataSend(data) : handleNext();
  };

  const transformData = (data: any) => {
    const dataTransform = dataRegister;
    dataTransform.shippingInformation = data
    return dataTransform;
  }
  const completeDataSend = async (data: any) => {
    const dataSubmit = transformData(data);
    setloadSubmit(true);
    const response: any = await preregistationService.post(dataSubmit);
    
    if (response.status === STATUS.success || response.status === STATUS.created) {
      Swal.fire({
        title: 'Thank you for signing up!',
        text: `Soon you’ll be part of best Peruvian Marketplace! We'll verify your information and we’ll contact you soon.`,
        iconColor: '#00B0FF',
        icon: 'success',
        confirmButtonColor: COLORS.PRIMARY,
        confirmButtonText: 'Acept',
        padding: '3rem',
      });
      cleanData();
      navigate('/');
    } else {
      if (response.status === STATUS.dataIncorrect) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
        cleanData();
        navigate('/');
      }
    };
    setloadSubmit(false);
    createResponseEvent({
      commandtrigger: 'form',
      commandtag: "user_event_create_register_international",
      sessionId: registerIdLog,
      emailAddress: registerEmailLog,
      formValues: {...data},
      pageError: "",
      method: response?.method,
      path: response?.url,
      statusCode: response?.status,
      message: response?.data?.mensaje
    });
  };

  const cleanData = async () => {
    const stepsInfo = stepsInformation;
    setresetForm(true);
    setdataRegister(initialValues);
    setActiveStep(0);
    const data = await stepsInfo.map((step) => {
      step.completed = false;
      return step;
    })
    setstepsInformation(data);
  };

  const handleBackForm = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "All data entered will be lost!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: COLORS.PRIMARY,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Back!'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
        cleanData();
      }
    });
  };

  const completedStep = (completed: boolean, nameStep: string) => {
    const stepsInfo = stepsInformation;
    stepsInfo[activeStep].completed = completed;
    setstepsInformation(stepsInfo);
  };

  const logError = (error: Error, info: { componentStack: string }) => {
    createResponseEvent({
      commandtrigger: 'form',
      commandtag: "user_event_error_register_international",
      sessionId: registerIdLog,
      emailAddress: registerEmailLog,
      pageError: info?.componentStack + error?.message + error?.stack
    });
  };

  return (
    <RegisterWrapper>
      <BackWrapper onClick={handleBackForm}>
        <Icon icon={icons.back} size="20px" fill="#096DD9" />
        <Label>Back</Label>
      </BackWrapper>
      <ModalLoader open={loadSubmit} />
      <ContentWrapper>
        <StepperSection
          stepsData={stepsInformation}
          activeStep={activeStep}
          orientation="horizontal"
        />
        <ErrorBoundary
          fallback={<div>Something went wrong</div>}
          onError={logError}
        >
          <CompanyInformation
            activeStep={activeStep}
            handleSubmit={sendData}
            completedStep={completedStep}
            resetForm={resetForm}
          />
        </ErrorBoundary>
        <ErrorBoundary
          fallback={<div>Something went wrong</div>}
          onError={logError}
        >
          <ContactInformation
            activeStep={activeStep}
            handleSubmit={sendData}
            completedStep={completedStep}
            handleBack={handleBack}
            resetForm={resetForm}
            registerIdLog={registerIdLog}
            registerEmailLog={registerEmailLog}
          />
        </ErrorBoundary>
        <ErrorBoundary
          fallback={<div>Something went wrong</div>}
          onError={logError}
        >
          <PaymentDataInformation
            activeStep={activeStep}
            handleSubmit={sendData}
            completedStep={completedStep}
            handleBack={handleBack}
            resetForm={resetForm}
            registerIdLog={registerIdLog}
            registerEmailLog={registerEmailLog}
          />
        </ErrorBoundary>
        <ErrorBoundary
          fallback={<div>Something went wrong</div>}
          onError={logError}
        >
          <PaymentContactInformation
            activeStep={activeStep}
            handleSubmit={sendData}
            completedStep={completedStep}
            handleBack={handleBack}
            resetForm={resetForm}
            registerIdLog={registerIdLog}
            registerEmailLog={registerEmailLog}
          />
        </ErrorBoundary>
        <ErrorBoundary
          fallback={<div>Something went wrong</div>}
          onError={logError}
        >
          <ShippingInformation
            activeStep={activeStep}
            handleSubmit={sendData}
            completedStep={completedStep}
            completeStepsLabel={stepsInformation}
            handleBack={handleBack}
            resetForm={resetForm}
            registerIdLog={registerIdLog}
            registerEmailLog={registerEmailLog}
          />
        </ErrorBoundary>
      </ContentWrapper>
    </RegisterWrapper>
  )
};

export default Register;