import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { GlobalStyle } from '../ui/globalStyle';
import { routes } from '../config/routes';
import Container from '../containers';
export const App: React.FC = () => (
  <>
    <Container>
      <RouterProvider router={routes} />
    </Container>
    <GlobalStyle />
  </>
);
